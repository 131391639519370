.logo_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-weight: bold;
}

.header,
.navigation_header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header {
    background-color: #2d2d2d;
    justify-content: space-between;
    padding: 0 10%;
    height: 3.5em;
    box-shadow: 1px 1px 4px #1c1c1c;
}

.navigation_header {
    gap: 1em;
    z-index: 2;
}

.content {
    padding-top: 5em;
    text-align: center;
    height: 100vh;
    transition: 1s;
}

.navigation_header_a {
    text-decoration: none;
    color: #fff;
    transition: 0.5s;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
}

.navigation_header_a:hover {
    background: #414141;
    padding: 10px;
    border-radius: 10px;
}

.navigation_header_a_out {
    text-decoration: none;
    color: #ff0000;
    transition: 0.5s;
    font-weight: bold;
}

.navigation_header_a_out:hover {
    background: #414141;
    padding: 10px;
    border-radius: 10px;
}

.active {
    background: #414141;
    padding: 10px;
    border-radius: 10px;
    color: #2563eb;
}

.btn_icon_header {
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    display: none;
}

@media screen and (max-width: 1024px) {
    .navigation_header {
        position: absolute;
        flex-direction: column !important;
        top: 0;
        background: #343434;
        height: 100%;
        width: 55vw;
        padding: 1em;
        animation-duration: 1s;
        margin-left: -100vw;
        text-align: center;
    }

    .btn_icon_header {
        display: block;
    }
}

@keyframes showSidebar {
    from {
        margin-left: -100vw;
    }

    to {
        margin-left: -10vw;
    }
}
