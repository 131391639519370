* {
    line-height: 1.7;
    outline: none;
}

main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.banner {
    width: 100vw;
    min-height: 10vh;
}

.banner div.banner-overlay {
    background-color: #f4f7ff;
    background-image: url("../../assets/home_bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    min-height: 10vh;
    display: flex;
    align-items: center;
    padding: 9rem 25em;
    position: relative;
    flex-direction: column;
    color: #011537;
}

.banner div.banner-overlay h1 {
    color: #011537;
    font-size: 2em;
    font-weight: 400;
    text-align: center;
}

.banner div.banner-overlay p {
    color: #49566d;
    text-shadow: 0px 0px 1px #49566d;
    font-weight: 400;
    font-size: 1.3em;
    text-align: center;
    margin-top: 4rem;
}

.banner div.banner-overlay .under {
    text-decoration: underline;
}

p, span {
    font-weight: lighter;
}

.seller-btn {
    padding: .75rem 1.5rem;
    border-radius: .25rem;
    border: 2px solid #011537;
    color: #011537;
    transition: .2s ease-in;
}

.seller-btn-mini {
    padding: .4rem .8rem;
    border-radius: .4rem;
    color: #011537;
    border: 2px solid #011537;
    transition: .2s ease-in;
    font-weight: 400;
}

.seller-btn-mini.full, .seller-btn.full {
    background-color: #011537;
    color: white;
}

.seller-btn:hover, .seller-btn-mini:hover {
    background-color: #011537;
    scale: 1.05;
    color: white;
}

.menu-home {
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: white;
    padding: 0 2rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #bbc6d8;
    /* box-shadow: 0px -7px 20px rgb(164, 165, 181); */
}

.menu-home nav {
    max-width: 1120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    width: 100%;
}

.menu-home ul {
    display: flex;
    list-style: none;
    align-items: center;
}

.menu-home ul li {
    cursor: pointer;
    margin-left: 1rem;
}

.menu-home ul li {
    cursor: pointer;
    margin-left: 1rem;
    transition: .3s ease-in;
}

.menu-home ul li:hover {
    scale: 1.05;
}

div.section {
    padding-bottom: 1rem !important;
}

div.section, div.section-extended {
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    max-width: 1120px;
    width: 100%;
}

div.section-extended {
    max-width: none;
    padding-top: 0px !important;
    padding-bottom: 5rem;
    margin-top: 2rem;
}

div.block-items-list {
    display: flex;
    flex-wrap: wrap;
}

div.block-items-list .block-item {
    width: calc(25% - 1.5em);
    padding: 1.25rem;
    background-color: rgba(129, 173, 238, 0.159);
    margin: 0 .75em;
    border-radius: .5rem;
}

div.block-items-list .block-item iframe {
    width: 100%;
    aspect-ratio: 16/9;
}

div.block-items-list .block-item span {
    font-weight: 200;
    margin-top: 2rem;
    display: block;
}

p.main-text {
    font-size: 1.3rem;
    font-weight: lighter;
    line-height: 1.8;
    text-align: center;
}

h2.sub-title {
    font-size: 2rem;
    font-weight: 500;
    margin-top: 4.3rem;
    margin-bottom: 1.5rem;
}

h2.sub-title.sub-title-no-space {
    margin-top: 0 !important;
}

ul {
    list-style: disc;
    text-align: left;
    margin-left: 2rem;
    font-weight: lighter;
}

.secondary-painted {
    background-color: #011537;
    color: white;
}

.secondary-painted h2.sub-title {
    color: #81adee;
}

.qa-line {
    margin-bottom: 1.6rem;
    padding-left: 1.5rem;
    position: relative;
}

.qa-line::after {
    content: '';
    position: absolute;
    left: 0;
    padding: 0;
    height: 100%;
    width: 4px;
    background-color: #011537;
    top: 0;
    border-radius: 1rem;
}

.qa-line-title {
    font-weight: 600;
    font-size: 1.125rem;
    padding-bottom: 1rem;
    display: block;
}

.skip-or {
    margin-top: 2rem;
    margin-bottom: 2rem;
    position: relative;
    font-size: .9rem;
    color: #00225c;
}

.skip-or::before {
    content: '';
    left: -1.5em;
    top: 50%;
    height: 1px;
    width: 1.3em;
    position: absolute;
    display: block;
    background-color: #00225c;
}

.skip-or::after {
    content: '';
    right: -1.5em;
    top: 50%;
    height: 1px;
    width: 1.3em;
    position: absolute;
    display: block;
    background-color: #00225c;
}

@media only screen and (max-width: 1300px) {
    .banner div.banner-overlay {
        padding: 9rem 17em !important;
    }
}

@media only screen and (max-width: 1120px) {
    .banner div.banner-overlay {
        padding: 9rem 15em !important;
    }
}

@media only screen and (max-width: 960px) {
    .banner div.banner-overlay {
        padding: 9rem 8em !important;
    }

    div.block-items-list .block-item {
        width: calc(50% - 1.5em) !important;
        margin-bottom: 1.5em !important;
    }
}

@media only screen and (max-width: 600px) {
    .banner div.banner-overlay {
        padding: 9rem 3em !important;
    }

    div.block-items-list .block-item {
        width: calc(100% - 1.5em) !important;
    }

    .menu-home ul {
        display: none;
    }
}