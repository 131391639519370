
.container-all-p{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
}
.div_input_span{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 5px;
}

.div_inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    gap: 10px;
  }

  @media (max-width: 600px) {
    .div_inputs {
      flex-direction: column;
    }
    .div_input_span{
        width: 100%;
    }
  }


  .titulo {
    text-align: center;
    margin-top: 10px;
    font-size: 2rem;
    font-weight: bold;
    color: #333;
  }
  
  .titulo strong {
    display: block; 
    font-size: 1.8rem; 
    color: #007bff; 
  }
  
  @media (max-width: 768px) {
    .titulo {
      font-size: 1.5rem;
    }
  
    .titulo strong {
      font-size: 1.2rem;
    }
  }
  