
.container-all-p{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
}

.div_input_span{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 5px;
}

.grid_reduction_before {
  padding-left: 1.4rem;
}

.grid_reduction_before::before {
  content: '1 : ';
  display: block;
  position: absolute;
  top: 50%;
  left: 0rem;
}

.div_inputs_sem_fim {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    gap: 10px;
  }

  @media (max-width: 600px) {
    .div_inputs_sem_fim {
      flex-direction: column;
    }
    .div_input_span{
        width: 100%;
    }
    .modal-container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .div_input_span_modal{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}
